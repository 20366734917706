@import '_variables.scss';

main {
  @apply flex w-full p-8 content-center items-center flex-col lg:flex-row;
}

.lead {
  @apply lg:max-w-[60%] mb-40 relative text-left animate-slide-in-left;
  &__hip {
    @apply text-4xl mob:text-5xl lg:text-7xl font-bold relative z-10 sm:pr-40;
    span {
      @apply text-secondary;
    }
  }
  a {
    @apply outline-secondary;
  }
  &__cta {
    @apply text-xl mt-8 bg-primary tracking-wider text-whiter rounded-lg p-4 font-mono font-bold sm:text-2xl border-transparent border-4 hover:border-whiter hover:rounded-none transition-all duration-300 justify-self-center shadow-lg shadow-black hover:shadow-none relative z-10 outline-secondary;
    background: linear-gradient(30deg, $primary, $secondary, $tertiary);
    background-clip: padding-box;
  }
  &__svk {
    @apply absolute max-w-[30rem] right-0 left-0 mx-auto -bottom-32 lg:right-8 lg:left-auto sm:top-40 px-4 animate-appear;
    animation-duration: 1200ms;
    .tooltip {
      @apply focus:outline-none;
      &:hover, &:target, &:focus {
        .beat {
          @apply origin-center;
          animation: beat 600ms ease-in-out infinite;
          transform-box: fill-box;
        }
        .tooltip__content {
          @apply block opacity-100;
        }
      }
      &--ba {
        .d {
          @apply fill-secondary;
        }
      }
      &__content {
        @apply hidden opacity-0 transition-opacity duration-500 ease-in-out;
        rect {
          @apply opacity-90 fill-bg;
          transform: translate(20px, 100px);
        }
        text {
          @apply text-[.6rem] fill-whiter font-bold font-mono;
        }
        &--ba {
          rect {
            @apply w-[4.5rem] h-4;
            transform: translate(20px, 102px);
          }
          text {
            transform: translate(26px, 113.5px);
          }
        }
        &--po {
          rect {
            @apply w-12 h-4;
            transform: translate(260px, 24px);
          }
          text {
            transform: translate(267px, 35.5px);
          }
        }
      }
    }
  }
}

.exp {
  @apply flex justify-center max-w-lg mb-12 animate-slide-in-right;
  animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  animation-duration: 800ms;
  ul {
    @apply flex flex-col justify-center;
  }
  &__item {
    @apply flex relative z-10 bg-whiter/95 px-4 mb-8 shadow-lg shadow-black rounded-2xl;
    perspective: 1000px;
    &__info {
      @apply flex flex-col justify-center p-4 text-bg;
      h2 {
        @apply text-2xl font-semibold font-mono tracking-wider;
      }
      p {
        @apply text-xl;
      }
    }
    // &-shadow {
    //   @apply bg-primary absolute w-full h-full z-0;
    //   bottom: -2.5rem;
    //   left: 1.25rem;
    // }
    // &:last-child {
    //   .exp__item-shadow {
    //     @apply shadow-lg;
    //   }
    // }
  }
}

@keyframes subtleDance {
  0%, 100% {
    transform: translate(0, 0);
  }
  33% {
    transform: translate(.25rem, .15rem) skew(-.5deg, .25deg);
  }
  66% {
    transform: translate(.15rem, -.075rem) skew(-.2deg, .1deg);
  }
}

.subtle-dance {
  animation: subtleDance 8s ease-in-out infinite;
  &.subtle-variation {
    animation-delay: 1s;
    animation-direction: reverse;
  }
  &.subtle-variation-2 {
    animation-delay: 3s;
  }
}

@keyframes beat {
  0% {
    transform: scale(1) skewY(2deg);
  }
  50% {
    transform: scale(1.5) skewY(0deg);
  }
  100% {
    transform: scale(1) skewY(2deg);
  }
}