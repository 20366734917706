.header {
  @apply w-full flex items-center justify-between p-4;
  &__logo {
    @apply ml-4 stroke-transparent animate-slide-in-top;
    img {
      @apply min-w-[5rem] w-20;
      filter: drop-shadow(0 0 1.5rem rgba(0, 0, 0, .5));
      &:hover {
        animation: logoFun 12s ease-in-out infinite;
      }
    }
  }
  &__nav {
    @apply font-mono animate-slide-in-top;
    animation-duration: 600ms;
    a {
      @apply outline-primary;
    }
    ul {
      @apply flex flex-col font-bold lowercase sm:flex-row;
    }
    li {
      @apply py-2 mx-4 transition-colors duration-200 ease-in-out border-transparent hover:border-b-4  hover:border-primary target:border-primary focus:border-primary;
    }
  }
}

@keyframes logoFun {
  0%, 59% {
    filter: grayscale(0);
    transform: translateX(0);
  }
  20%, 40% {
    filter: grayscale(25%);
  }
  60%, 89% {
    transform: translateX(-999px) rotate(-30deg);
  }
  95% {
    transform: translateX(128px) rotate(30deg);
  }
  97% {
    trasnform: translateX(0) rotate(-10deg);
  }
  100% {
    transform: translateX(0) rotate(0);
  }
}