@import '_variables';

.projects {
  @apply w-full p-8;
  &__list {
    @apply grid grid-cols-1 lg:grid-cols-2 gap-x-12 gap-y-24 mt-16;
  }
}

.project {
  @apply relative w-full flex flex-col md:flex-row px-4 py-8 bg-whiter rounded-2xl text-bg shadow-black shadow-lg border-4 border-secondary odd:animate-slide-in-left even:animate-slide-in-right;
  &__name {
    @apply absolute font-bold bg-secondary p-4 rounded-xl -top-8 right-4 text-whiter text-xl;
    span {
      @apply font-mono;
    }
  }
  &__logo {
    @apply min-w-[8rem] max-w-[8rem] h-auto flex justify-center items-center my-6 mr-0 sm:my-0 sm:mr-4;
  }
  &__body {
    @apply w-full flex flex-col items-center sm:flex-row text-xl;
  }
  &__about {
    @apply flex flex-col;
  }
  &__tech {
    @apply font-mono font-bold max-w-[32ch] py-2;
  }
  &__purpose {
    @apply max-w-[50ch] mb-16 md:mb-12;
  }
  &__preview {
    @apply absolute right-4 bottom-2 px-4 py-2 bg-bg text-whiter font-bold rounded-lg my-2 shadow-md shadow-bg hover:shadow-none transition-all duration-200 ease-in-out outline-secondary border-transparent border-4 hover:border-primary hover:rounded-none;
    &::after {
      @apply absolute w-8 h-3 -right-6 bottom-4 bg-bg transition-all duration-200 ease-in-out;
      content: '';
    }
    &:hover {
      &::after {
        @apply w-0;
      }
    }
  }
}

.projects-other {
  @apply w-full text-center text-2xl mt-16 animate-appear;
  animation-duration: 800ms;
}