$bg: #17142f;
$primary: #10b981;
$secondary: #8b5cf6;
$tertiary: #00dae3;
$whiter: #fafafa;

:root {
  --bg: #17142f;
  --primary: #10b981;
  --secondary: #8b5cf6;
  --tertiary: #00dae3;
  --whiter: #fafafa;
}
