.contact {
  @apply w-full p-8 overflow-x-hidden animate-slide-in-bottom;
  animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  animation-duration: 800ms;
  section {
    @apply flex max-w-3xl mx-auto justify-between my-16 items-center flex-col lg:flex-row;
  }
  &__link {
    @apply relative;
    &:hover,
    &:target,
    &:focus {
      @apply outline-secondary;
    }
    button {
      @apply font-mono;
      &:hover,
      &:target,
      &:focus {
        @apply outline-secondary;
      }
    }
    &.email {
      button {
        @apply bg-secondary;
      }
      img {
        @apply -right-16 -top-14;
      }
    }
    &.github {
      button {
        @apply bg-primary;
      }
      img {
        @apply -left-16 -bottom-14;
      }
    }
  }
  button {
    @apply text-2xl my-4 tracking-wider text-whiter rounded-2xl p-4 font-bold border-transparent border-4 hover:border-whiter hover:rounded-none transition-all duration-300 justify-self-center shadow-lg shadow-black hover:shadow-none relative max-w-[20rem] z-10;
  }
  p {
    @apply font-bold font-mono text-whiter text-center py-4;
  }
  img {
    @apply h-24 w-auto absolute mx-auto opacity-25;
  }
}

hr {
  @apply text-whiter max-w-4xl mx-auto border-2 mb-4;
}