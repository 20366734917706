@tailwind base;
@tailwind components;
@tailwind utilities;
@import '_variables';

html, body {
  @apply scroll-smooth bg-bg text-whiter;
  background-size: 4rem 4rem;
  background-image:
    linear-gradient(to right, rgba(#fafafa, .03) 1px, transparent 1px),
    linear-gradient(to bottom, rgba(#fafafa, .03) 1px, transparent 1px);
}

::selection {
  @apply bg-primary text-whiter;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &::-webkit-scrollbar {
    width: .75rem;
  }
  &::-webkit-scrollbar-track {
    @apply bg-transparent rounded-md mt-4;
  }
  &::-webkit-scrollbar-thumb {
    @apply bg-secondary/10 rounded-md outline outline-1 outline-secondary/30;
  }
}

.section-title {
  @apply text-2xl tracking-widest font-mono font-bold lowercase border-b-4 border-primary max-w-fit py-2;
}

.title {
  @apply relative;
  &::before {
    @apply absolute -top-16 right-0 w-fit py-2 px-4 hidden text-xs bg-bg/95 shadow-md shadow-bg/75 rounded-lg border-2 border-primary;
    content: attr(data-title);
  }
  &--below {
    &::before {
      @apply -bottom-12 top-auto left-[.3rem] font-mono font-bold;
    }
  }
  &:hover, &:target, &:focus {
    &::before {
      @apply block animate-appear;
    }
  }
}